.slider {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  overflow-x: scroll;
}

.slider::-webkit-scrollbar {
  display: none;
}

.sliderItem > img {
  width: 300px;
  height: 220px;
  margin-right: 30px;
  border-radius: 10px;
}

.sliderItem > svg {
  width: 300px;
  height: 220px;
  margin-right: 30px;
}

@media print {
  .slider {
    display: flex;
    overflow-x: unset;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .sliderItem > img {
    width: 200px;
    height: 180px;
    margin-right: 0;
    border-radius: 10px;
  }

  .sliderItem > svg {
    width: 200px;
    height: 180px;
    margin-right: 0;
  }

  .sliderItem > p {
    font-size: 13px;
    white-space: nowrap;
  }
}
