.StyledVideo {
  width: 100%;
  height: 100%;
  flex: 1;
}

.StyledCardFooter {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
}

.StyledContent {
  display: flex;
  flex-direction: column;
}
